import { createActionGroup, props } from '@ngrx/store';
import { FirebaseNode } from '../../shared/models/firebase.model';
import { CryptoPost } from '../../shared/models/crypto-post.model';
import { ActiveOrdersListState } from './active-orders-list.types';

export const activeOrdersListActions = createActionGroup({
  source: 'activeOrdersList',
  events: {
    setActiveOrdersList: props<{ activeOrdersList: ActiveOrdersListState }>(),
    setOrdersList: props<{ ordersList: Array<FirebaseNode & CryptoPost> }>(),
  },
});
