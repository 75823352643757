import { DestroyRef, Injectable } from '@angular/core';
import { TransactionPostService } from '../post/transaction-post.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import {
  CryptoPost,
  GetPostsByMyTransactions,
  TradeOperationStatusEnum,
} from '../../../shared/models/crypto-post.model';
import { FirebaseNode, FirebaseNodeType } from '../../../shared/models/firebase.model';
import { PostTypeEnum } from '../../../shared/pages/create-post/create-post.model';
import { activeOrdersListActions } from '../../../store/active-orders-list/active-orders-list.actions';
import { FirebaseDataService } from '../firebase/firebase-data-service.service';
import { FIREBASE_TRANSACTIONS_NODE_NAME } from '../../../shared/constants/firebase.constants';

const ACTIVE_STATUS_LIST = [
  TradeOperationStatusEnum.OPENED,
  TradeOperationStatusEnum.IN_PROGRESS,
  TradeOperationStatusEnum.PAID_NOTIFIED,
  TradeOperationStatusEnum.CONFLICTED,
  TradeOperationStatusEnum.COMPLETED,
];
@Injectable({
  providedIn: 'root',
})
export class OrderListHandlerService {
  constructor(
    private transactionPostService: TransactionPostService,
    private destroyRef: DestroyRef,
    private store: Store,
    private firebaseDataService: FirebaseDataService
  ) {}

  public listenAllMyPostsChanges(): void {
    this.transactionPostService
      .getPostsByMyTransactions(ACTIVE_STATUS_LIST)
      .subscribe((response) => {
        const ordersList: Array<FirebaseNode & CryptoPost> =
          this.buildTransactionList(response);
        this.store.dispatch(
          activeOrdersListActions.setOrdersList({
            ordersList: ordersList,
          })
        );

        this.firebaseDataService
          .listenToNodes(ordersList, FIREBASE_TRANSACTIONS_NODE_NAME)
          .forEach((post) => {
            post.changes
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe((changes) => {
                if (changes) {
                  console.log('JSON from firebase', JSON.stringify(changes));
                  this.refreshChangedPosts();
                }
              });
          });
      });
  }

  private refreshChangedPosts(): void {
    this.transactionPostService
      .getPostsByMyTransactions(ACTIVE_STATUS_LIST)
      .subscribe((response) => {
        const ordersList: Array<FirebaseNode & CryptoPost> =
          this.buildTransactionList(response);

        this.store.dispatch(
          activeOrdersListActions.setOrdersList({
            ordersList: ordersList,
          })
        );
      });
  }

  private buildTransactionList(
    response: GetPostsByMyTransactions
  ): Array<FirebaseNode & CryptoPost> {
    return [
      ...this.mapPosts(response.ownPost.buyPost, PostTypeEnum.Buy, true),
      ...this.mapPosts(response.ownPost.sellPost, PostTypeEnum.Sell, true),
      ...this.mapPosts(response.interactPost.buyPost, PostTypeEnum.Buy, false),
      ...this.mapPosts(response.interactPost.sellPosts, PostTypeEnum.Sell, false),
    ];
  }

  private mapPosts(
    posts: any[],
    postType: PostTypeEnum,
    isOwner: boolean
  ): Array<FirebaseNode & CryptoPost> {
    return posts.map((p) => ({
      ...p,
      nodeType: FirebaseNodeType.POST,
      postId: p._id,
      postType,
      isPostOwner: isOwner,
    }));
  }
}
