import { Injectable, OnDestroy } from '@angular/core';
import { Database, onValue, ref, set } from '@angular/fire/database';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import {
  FirebaseNode,
  FirebaseNodeObservable,
} from '../../../shared/models/firebase.model';
import { TradeOperationStatusEnum } from '../../../shared/models/crypto-post.model';
import { buildPostNodeIdPath } from '../../../shared/helpers/firebase.helper';

@Injectable({
  providedIn: 'root',
})
export class FirebaseDataService implements OnDestroy {
  private destroyedRef = new Subject<void>();
  private compositeSubscription = new Subscription();

  constructor(private db: Database) {}

  public async setStatus(
    firebaseNode: FirebaseNode,
    status: TradeOperationStatusEnum
  ): Promise<void> {
    const nodeId = buildPostNodeIdPath(firebaseNode) + '/currentStatus';
    console.log('nodeId', nodeId);
    const itemRef = ref(this.db, nodeId);
    await set(itemRef, {
      date: Date.now(),
      status: status,
    });
  }

  public listenToNodes(
    firebaseNodes: FirebaseNode[],
    additionalPath?: string | undefined
  ): FirebaseNodeObservable[] {
    return firebaseNodes.map((node) => {
      let nodeId = buildPostNodeIdPath(node);
      if (additionalPath) {
        nodeId = nodeId + `/${additionalPath}`;
      }
      const changes$ = this.getNodeData(nodeId);
      const subscription = changes$.subscribe();
      this.compositeSubscription.add(subscription);

      return {
        postId: node.postId,
        nodeType: node.nodeType,
        postType: node.postType,
        transactionId: node.transactionId,
        changes: changes$,
      };
    });
  }

  private getNodeData(path: string): Observable<any> {
    return new Observable((observer) => {
      const dbRef = ref(this.db, path);
      const unsubscribe = onValue(dbRef, (snapshot) => {
        observer.next(snapshot.val());
      });

      return () => unsubscribe();
    }).pipe(takeUntil(this.destroyedRef));
  }

  ngOnDestroy(): void {
    this.destroyedRef.next();
    this.destroyedRef.complete();
    this.compositeSubscription.unsubscribe();
  }
}
