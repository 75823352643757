import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  GetPostsByMyTransactions,
  TradeOperationStatusEnum,
} from '../../../shared/models/crypto-post.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionPostService {
  private transactionPost = `${environment.backendUrl}/user`;

  constructor(private http: HttpClient) {}

  public getPostsByMyTransactions(
    status: TradeOperationStatusEnum[]
  ): Observable<GetPostsByMyTransactions> {
    return this.http.get<GetPostsByMyTransactions>(
      `${this.transactionPost}/list-post-by-my-transactions`,
      {
        params: {
          status: status,
        },
      }
    );
  }
}
